import { gsap } from 'gsap';
export default function atc_single() {
    let activeIndex = 0;


    // Get button with class .atc-next-button
    const next = document.querySelector('.atc-next-button');
    const prev = document.querySelector('.atc-prev-button');
    if (!next) {
        console.error("Next button not found");
        return;
    }

    // Get all slides with class .atc-image--wrapper
    const slides = document.querySelectorAll(".atc-image--wrapper");
    if (slides.length === 0) {
        console.error("No slides found");
        return;
    }
    let totalSlides = slides.length;



    function setInitialState() {
        slides.forEach((slide, index) => {
            if (index !== activeIndex) {
                gsap.set(slide, { clipPath: "polygon(0.1% 0.1%, 0.1% 0.1%, 0.1% 100%, 0.1% 100%)" });
            } else {
                gsap.set(slide, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" });
            }
        });

    }

    function moveSlide(nextIndex, forwards = false) {


        if (nextIndex >= slides.length) {
            nextIndex = 0;
        }

        let prevItem = slides[activeIndex];
        let nextItem = slides[nextIndex];


        let tl = gsap.timeline({ defaults: { duration: 1, ease: "power2.inOut" } });

        // Debugging: Log the elements before animation


        // tl.fromTo(nextItem,
        //     { clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)" },
        //     { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" }
        // )
        //     .fromTo(prevItem,
        //         { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" },
        //         { clipPath: "polygon(0.1% 0.1%, 0.1% 0.1%, 0.1% 100%, 0.1% 100%)" }, "<"
        //     )
        //     .eventCallback("onComplete", () => {
        //         isAnimating = false; // Reset flag after animation completes
        //
        //     });

        if (forwards) {
            tl.fromTo(nextItem, { clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)" }, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, -30% 100%)" });
            tl.fromTo(prevItem, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" }, { clipPath: "polygon(0% 0%, 0% 0%, -30% 100%, 0% 100%)" }, "<");
        } else {
            tl.fromTo(nextItem, { clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" }, { clipPath: "polygon(0% 0%, 100% 0%, 130% 100%, 0% 100%)" });
            tl.fromTo(prevItem, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" }, { clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 130% 100%)" }, "<");
        }


        activeIndex = nextIndex;


    }

    setInitialState(); // Set the initial state of the slides

    function goNext(num) {
        let nextIndex = num;
        if (nextIndex > totalSlides - 1) nextIndex = 0;
        moveSlide(nextIndex, true);
    }

    next.addEventListener("click", function () {

        let totalSlides = slides.length;
        let nextIndex = activeIndex + 1;
        if (nextIndex >= totalSlides) nextIndex = 0;
        goNext(activeIndex + 1);
    });

    prev.addEventListener("click", function () {
        let nextIndex = activeIndex - 1;
        if (nextIndex < 0) nextIndex = totalSlides - 1;
        moveSlide(nextIndex);
    });
}
