import {gsap} from "gsap";



function animateMainMenuOpening() {
    let tl = gsap.timeline({ ease: 'power4.inOut', paused: true });
    const navItems = gsap.utils.toArray('.nav-items > li > a, .nav-items > li > div.brx-submenu-toggle > span');
    const navWrapper = document.querySelector('.nav-items--wrapper');


    let mainNavTl = gsap.timeline(
        { paused: true, ease: 'power4.inOut' })
        .add('start')
        .to(navWrapper, { duration: 0.3, y: 0 })
        .add('middle')
        .to(navItems, {
            duration: 0.5,
            y: '0%',
            clipPath: 'polygon(0% 105%, 100% 105%, 100% 0%, 0% 0%)',
            stagger: 0.1,
        })
        .add('end');

    return mainNavTl;
}

function animateSubMenuOpening() {
    const submenuContent = document.querySelector('.nav-items__content');
    const subNavItems = gsap.utils.toArray('.nav-items__content > li > a');
    const backBtn = document.querySelector('.nav-items .nav-items__dropdown .brx-submenu-toggle button');

    let tl = gsap.timeline({ ease: 'power4.inOut', paused: true });




    tl.add(animateMainMenu.tweenFromTo('end', 'middle'))
        .add('start')
        .to(submenuContent, { duration: 0.3, opacity: 1})
        .to(subNavItems, {
            duration: 0.5,
            opacity: 1,
            y: '0%',
            clipPath: 'polygon(0% 105%, 100% 105%, 100% 0%, 0% 0%)',
            stagger: 0.1,
        })
        .to(backBtn, {
            duration: 0.1,
            opacity: 1,
            y: '0%',
            clipPath: 'polygon(0% 105%, 100% 105%, 100% 0%, 0% 0%)',
        })
        .add('end');


    return tl;
}


let animateMainMenu = animateMainMenuOpening();
let animateSubMenu = animateSubMenuOpening();





function mainMenuObserver() {
    const nav = document.querySelector('.brxe-nav-nested.nav');
    // select button with the following classes: brxe-toggle nav-toggle brxa--spin
    const navToggle = document.querySelector('.brxe-toggle.nav-toggle.brxa--spin');
    const submenu = document.querySelector('.nav-items__dropdown');
    navToggle.addEventListener('click', (event) => {
        // check if gsap-open class is present
        if (nav.classList.contains('gsap-open')) {
            //check if submenu is open
            nav.classList.toggle('gsap-open');
            animateMainMenu.timeScale(3).reverse();

        } else {
            if(submenu.classList.contains('gsap-open')){
                submenu.classList.toggle('gsap-open');
                animateSubMenu.tweenFromTo('end', 'start');
            }
            nav.classList.toggle('gsap-open');
            animateMainMenu.play();
        }
    });


}

function subMenuObserver() {
       const submenuButtons = document.querySelectorAll('.nav-items__dropdown');

    // Loop through the buttons and add click event listeners
    submenuButtons.forEach(button => {
        button.addEventListener('click', (event) => {
            // check if gsap-open class is present
            if (button.classList.contains('gsap-open')) {
                button.classList.toggle('gsap-open');
                animateSubMenu.timeScale(3).reverse();
            }else{
                button.classList.toggle('gsap-open');
                //reverse gsap animation by 1 animation
                //mainNavTl.timeScale(2).tweenFromTo('end', 'middle');
                animateSubMenu.play();
            }

        });
    });



}

function resetStyles() {
    const navItems = gsap.utils.toArray('.nav-items > li > a, .nav-items > li > div.brx-submenu-toggle > span');
    const navWrapper = document.querySelector('.nav-items--wrapper');
    const submenuContent = document.querySelector('.nav-items__content');
    const subNavItems = gsap.utils.toArray('.nav-items__content > li > a');
    const backBtn = document.querySelector('.nav-items .nav-items__dropdown .brx-submenu-toggle button');

    // Kill all tweens associated with these elements
    gsap.killTweensOf([navWrapper, ...navItems, submenuContent, ...subNavItems, backBtn]);

    // Clear all inline styles applied by GSAP
    gsap.set([ navWrapper, ...navItems, submenuContent, ...subNavItems, backBtn], { clearProps: "all" });
    animateMainMenu = animateMainMenuOpening();
    animateSubMenu = animateSubMenuOpening();
}


//check for swup clicks
function checkMenuClicks(){

    const nav = document.querySelector('.brxe-nav-nested.nav');
    // select button with the following classes: brxe-toggle nav-toggle brxa--spin

    const submenu = document.querySelector('.nav-items__dropdown');

    if (nav) {
        if (nav.classList.contains('gsap-open')) {
            console.log('nav is open');

            if (submenu && submenu.classList.contains('gsap-open')) {
                submenu.classList.toggle('gsap-open');
                animateSubMenu.tweenFromTo('end', 'start').then(resetStyles);
            }

            nav.classList.toggle('gsap-open');
            animateMainMenu.tweenFromTo('end', 'start').then(resetStyles);
        }
        //resetStyles();
    } else {
        console.error('Navigation element not found');
    }


        // if (nav.classList.contains('gsap-open')) {
        //     //check if submenu is open
        //     console.log('nav is open');
        //
        //
        //     if (submenu.classList.contains('gsap-open')) {
        //         submenu.classList.toggle('gsap-open');
        //         animateSubMenu.tweenFromTo('end', 'start').then(resetStyles);
        //     }
        //
        //     nav.classList.toggle('gsap-open');
        //     animateMainMenu.timeScale(3).reverse();
        // }
        // resetStyles();


}


export {mainMenuObserver, subMenuObserver, checkMenuClicks};