import { gsap } from 'gsap';

export const base = () => {

    const main = document.querySelectorAll('main > .brxe-section');
    function leave(){
        const tl = gsap.timeline({ease: 'power4.inOut', paused: true});
        tl.to(main, {duration: 0.5, opacity: 0})

        return tl;
    }

    function enter(){
        const tl = gsap.timeline({ease: 'power4.inOut', paused: true});
        tl.to(main, {opacity: 1, duration: 0.5})
        return tl;
    }




    return { leave, enter };
}