import { gsap } from 'gsap';
import {commissionAnimations} from "../Animations/commissionAnimations";

function categoryNavInit(){


    const catLinks = gsap.utils.toArray('.comm-category-list a');
    catLinks.forEach((link) => {
        link.addEventListener('mouseleave', (e) => {
            link.classList.add('animate-out');
            setTimeout(() => {
                link.classList.remove('animate-out');
            }, 300)
        });
    });
    commissionAnimations().enter();
}


export {categoryNavInit};