import {gsap} from 'gsap';
// image =  article-section-lima__card-media
// image warpper =  article-section-lima__card-media-wrapper
export const commissionAnimations = () => {


    let allimages = document.querySelectorAll('.article-section-lima__card-media > img');

    const enter = () => {
        gsap.set(allimages, {clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"})
        //clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)
        gsap.set('.intro__heading', {clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"})
        let tl = gsap.timeline({ease: 'power4.inOut', duration: 0.5});

        tl.to('.intro__heading', {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"})
            .fromTo('.comm-category-list a', {yPercent: 100, opacity: 0}, {
                yPercent: 0,
                opacity: 1,
                stagger: 0.2,
            }, "<")
            .to(allimages, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", stagger: 0.1, duration: 0.9}, "<")

    }

    const leave = () => {
        //gsap.set(allimages, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"})
        let tl = gsap.timeline({ease: 'power4.inOut', duration: 0.5});
        tl.to('.intro__heading', {clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"})
            .to('.comm-category-list a', {yPercent: 100, opacity: 0, stagger: 0.2}, "<")
            .to(allimages, {
                clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)", // Moves the clip-path to the right, hiding the image
                duration: 0.9,
                stagger: 0.1
            }, "<");

    }


    const enterCat = () => {

        gsap.set(allimages, {clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"})
        let tl = gsap.timeline({ease: 'power4.inOut', duration: 0.5});
        tl.fromTo('.intro__heading', {yPercent: 100, opacity: 0}, {
            yPercent: 0,
            opacity: 1,

        })
            .to(allimages, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", stagger: 0.1, duration: 0.9}, "<")
    }

    const leaveCat = () =>{
        let tl = gsap.timeline({ease: 'power4.inOut', duration: 0.5});
        tl.to('.intro__heading', {
            yPercent: 100,
            opacity: 0,
        })
            .to(allimages, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", stagger: 0.1, duration: 0.9}, "<")
    }

    return {enter, leave, enterCat, leaveCat};

}