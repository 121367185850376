import { gsap } from 'gsap';

export default function newsArchive() {
    if (document.querySelector(".fr-article-card-bravo")) {
        const grid = document.querySelector(".fr-feature-grid-victor");


        document.addEventListener("mousemove", (e) => {
            const rect = grid.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            grid.style.setProperty("--x", x + "px");
            grid.style.setProperty("--y", y + "px");

        });
    }
}