import '../sass/main.scss';
import { mainMenuObserver,subMenuObserver, checkMenuClicks } from "./components/menu";
import newsArchive  from "./pages/newsarchive";
import {categoryNavInit} from "./pages/ATCarchive";
import { gsap } from 'gsap';
import atc_single from "./pages/ATCsingle";
import {base} from "./Animations/base";
import {commissionAnimations} from "./Animations/commissionAnimations";



function pageInits() {



    if (document.body.classList.contains('post-type-archive-available-to-comm') || document.body.classList.contains('post-type-archive-product')) {
        categoryNavInit();
    }

    if(document.body.classList.contains('tax-ATC-category') || document.body.classList.contains('tax-product_cat')) {
        commissionAnimations().enterCat();
    }

    if (document.body.classList.contains('blog')) {
        newsArchive();
    }

    if (document.body.classList.contains('post-type-archive-product')) {
        categoryNavInit();
    }

    if (document.body.classList.contains('single-available-to-comm')) {
        atc_single();
    }

    //single-product
    if (document.body.classList.contains('single-product')) {
        atc_single();
    }

}

document.addEventListener('DOMContentLoaded', function () {
    mainMenuObserver();
    subMenuObserver();
    swupListeners();
    pageInits();
    base().enter().play();





});



function swupListeners1() {
    let fromPageUrl = window.location.pathname;
    let toPageUrl = '';
    let pageTransitionType = '';

    document.addEventListener('swup:visit:start', function (event) {
        fromPageUrl = window.location.pathname;
        toPageUrl = event.detail.visit.to.url || '';
        window.pageTransitionType  = checkPage(fromPageUrl, toPageUrl);
    });





    //https://swup.js.org/hooks/#animation-out-start
    document.addEventListener('swup:animationOutStart', function () {
        let main = document.querySelector('main > .brxe-section');
        gsap.to(main, {duration: 0.5, opacity: 0});
    });



    //https://swup.js.org/hooks/#content-replace
    document.addEventListener('swup:contentReplaced', function () {
        let main = document.querySelector('main > .brxe-section');
        gsap.to(main, {duration: 0.5, opacity: 1});
        pageInits();
    });




}
function checkPage(fromPageUrl, toPageUrl) {

    const commissionPathRegex = /^\/available-to-comm\/[^\/]+\/?$/;
    const commissionCategoryPathRegex =  /^\/available-to-commission\/[^\/]+\/?$/;

    // Define an array of valid page URL pairs with their corresponding return strings
    const validPagePairs = [
        { from: '/available-to-commission/', to: commissionPathRegex, result: 'atc_Archive_to_act_Single' },
        { from: '/available-to-commission/', to: commissionCategoryPathRegex, result: 'atc_Archive_to_cat' },

    ];

    // Check if the provided URLs match any valid pair and return the corresponding string
    for (let pair of validPagePairs) {
        if (fromPageUrl === pair.from && pair.to.test(toPageUrl)) {
            return pair.result;
        }
    }

    return 'no_match'; // Default return value if no match is found
}



function swupListeners() {
    let dwtSwup = '';
    //const swup = window.bricksforgeData.swupInstances[0];

    const events = [
        'animation:out:start',
        'animation:out:await',
        'animation:out:end',
        'animation:in:start',
        'animation:in:await',
        'animation:in:end',
        'animation:skip',
        'cache:set',
        'cache:clear',
        'content:replace',
        'content:scroll',
        'enable',
        'disable',
        'fetch:error',
        'fetch:request',
        'fetch:timeout',
        'history:popstate',
        'link:click',
        'link:self',
        'link:anchor',
        'link:newtab',
        'page:load',
        'page:view',
        'scroll:top',
        'scroll:anchor',
        'visit:start',
        'visit:end',
        'visit:abort'
    ];

    document.addEventListener('swup:enable', function() {
        if (typeof bricksforgeData !== 'undefined' && bricksforgeData.swupInstances && bricksforgeData.swupInstances.length > 0) {
            dwtSwup = bricksforgeData.swupInstances[0];
            console.log("Swup instance found.");
            swupTransitions(dwtSwup);

        } else {
            console.error("Swup instance not found.");
        }



        });
}




function swupTransitions(dwtSwup){
    let fromPageUrl = window.location.pathname;
    let toPageUrl = '';
    let pageTransitionType = '';

    dwtSwup.hooks.on('link:click', () => {
        checkMenuClicks();
    });

    dwtSwup.hooks.on('animation:out:start', async () => {

        if(pageTransitionType === 'atc_Archive_to_cat') {
            await commissionAnimations().leave();
        }

        if(pageTransitionType === 'atc_Archive_to_act_Single'){
            await commissionAnimations().leave();
        }

        if(pageTransitionType === 'no_match'){
            await base().leave().play();
        }


    });

    dwtSwup.hooks.on('animation:in:end', () => {
        base().enter().play();
    });

    dwtSwup.hooks.on('page:view', () => {
        base().enter().play();
        pageInits();
    });


    dwtSwup.hooks.on('visit:start', function (visit) {
        fromPageUrl = window.location.pathname;
        toPageUrl = visit.to.url || '';
        pageTransitionType  = checkPage(fromPageUrl, toPageUrl);
        console.log('pageTransitionType', pageTransitionType);
    });

}




